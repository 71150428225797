import { load } from '@shopify/theme-sections';
import ProductSlider from '../components/product-slider';

const sliders = document.querySelectorAll('[data-product-slider]');
if (sliders) {
    sliders.forEach(slider => {
        // eslint-disable-next-line no-new
        new ProductSlider(slider);
    });
}

const videos = document.querySelectorAll('[data-video]');
if (videos) {
    videos.forEach(video => {
        const iframe = video.querySelector('iframe');
        if(iframe) {
            iframe.onload = () => {
                video.classList.add('iframe-loaded');
            };
        }  
    });
}

load('*');
